<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">오카야마 지역의 매력적인 관광지와 인기 시설을 대상으로 한 가성비 있는 티켓입니다. 유효 기간 내에 대상 시설 중에서 원하는 시설을 3곳  선택하여 이용하실 수 있습니다. E-티켓에 기재된 QR 코드를 제시하시면 간편하게 입장할 수 있습니다.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">사용방법</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● 사용방법 : 예약 후 메일로 1장의 PDF를 수령한 후, 이용기간 중 해당 시설에 방문하여 QR코드를 제시한 뒤 교환해 주세요. <br>
          ● 각 시설은 1회 한정으로 이용 가능하며, 동일 시설에 재입장하실 수 없습니다. ● 이용 개시 가능일 : 구입일로부터 <span>90일간</span> <br>
          예시 : <span>7/1</span> 구입 ⇒ <span>이용 가능 기간 : 7/1~9/28</span> <br>
          ● 해당 패스는 첫 번째 시설 이용부터 <span>7일간</span> 유효합니다. <br>
          예시 : 첫 번째 시설 이용일 <span>7/1</span> ⇒ <span>유효기간 7/1~7/7</span> <br>
          ● 이용 가능 시설 : 유효 기간(7일) 내 <span>3개까지 </span> 이용 가능<br>
          ● 1회 주문당 여러 개의 패스를 구입하는 경우, 모든 패스의 유효 기간은 동일하므로 주의하시기 바랍니다. (<span>이용 개시부터 7일간</span>) <br>
          ● 유효 기간은 이용 개시부터 7일간이지만, 구입일로부터 <span>90일</span> 이후에는 이용하실 수 없으므로 주의하시기 바랍니다. <br>
          예시 : 구입일이 <span>7/1</span>인 경우, <span>7/1~9/28</span>에만 이용 가능합니다. <span>9/27</span>부터 이용 개시하셔도 <span>9/28</span>까지만 이용하실 수 있습니다. <br>
          ● 미사용 시설은 환불되지 않습니다. <br>
          ● 각 시설의 주의사항, 영업시간, 정기휴일 등은 다음 링크에서 확인해 주세요. 
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='주의사항'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='티켓교환장소' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='공식 사이트' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfokayama/kr/havefun_title-kr.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 10,
          img: require('@/assets/images/hfokayama/10_EN.png'),
          title: ['오카야마성 천수각 입장권+천수각 1층 "우조 카페" 계절 파르페'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '2-chōme-3-1 Marunouchi, Kita Ward, Okayama ',
                '천수각 입장권 : ',
                'QR코드가 기재된 E티켓을 오카야마성 천수각 입장권 티켓 판매소 직원에게 제시하여 입장권으로 교환해 주세요. ',
                '"우조 카페" 계절 파르페 : ',
                '천수각 1층 "우조 카페" 직원에게 E티켓과 천수각 입장권을 함께 제시해 주시면, 입장권을 "계절 파르페"로 교환해 드립니다.',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['오카야마성 :'],noColor:true },
                { cid: 2, text: ['https://okayama-castle.jp/home-ko/ '] },
                { cid: 3, text: ['오카야마성 천수각 1층 "우조 카페" :'],noColor:true },
                { cid: 4, text: ['https://okayama-castle.jp/gather-shop/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요. ','<br>※ 정기 휴일 : 12/29～12/31 '] },
                { cid: 2, text: ['만석으로 인해 이용할 수 없는 경우가 있습니다. 미리 양해 부탁드립니다. '] },
                { cid: 3, text: ['상품 내용의 사진은 참고용으로 실제 제공되는 상품과 다를 수 있습니다.'] },
                ]
            }
          ]
        },
        {
          id: 1,
          img: require('@/assets/images/hfokayama/01_EN.png'),
          title: ['오카야마현 유메지 향토 미술관 본관 입장권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒703-8256 오카야마시 나카구 하마 2-1-32'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://yumeji-art-museum.com/korean/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['시간을 사전에 공식사이트에서 확인해 주세요.'] },
                { cid: 2, text: ['본관에만 입장 가능합니다. (소년 산장에 입장하시려면 별도 요금이 필요합니다.)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hfokayama/02_EN.png'),
          title: ["호텔 그란비아 오카야마 'Lumiere' 1,000엔 쿠폰"],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒700-8515 오카야마시 기타구역 모토마치 1번 5'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.granvia-oka.co.jp/ko/restaurant/#lum'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용할 수 있습니다.'] },
                { cid: 2, text: ['이용가능시간 : 9:00-21:00 (라스트오더 20:30) 단, 파르페 주문 가능 시간은 12:00-21:00 (라스트오더 20:30)'] },
                { cid: 3, text: ['계절에 따라 과일 파르페의 과일이 달라집니다. 상품의 사진은 이미지입니다.'] },
                { cid: 4, text: ['쿠폰 금액을 초과할 경우, 현지에서 추가 결제해 주세요.'] },
                { cid: 5, text: ['계산 후에는 환불 불가합니다.'] },
                { cid: 6, text: ['만석으로 인해 이용할 수 없는 경우가 있습니다. 미리 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 3,
          img: require('@/assets/images/hfokayama/03_EN.png'),
          title: ['오카야마현 오카덴 뮤지엄 입장권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒703-8281 오카야마현 오카야마시 나카구 히가시야마 2가 3-33'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://okayama-kido.co.jp/okadenmuseum/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['시간을 사전에 공식사이트에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 4,
          img: require('@/assets/images/hfokayama/04_EN.png'),
          title: ['café Antena 1,000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒700-0812 오카야마시 기타구 이즈시쵸 1-8-23'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://okayama-parfait.com/parfait/264.html'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰을 이용할 수 있습니다.'] },
                { cid: 2, text: ['시간을 사전에 공식사이트에서 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 4, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 5,
          img: require('@/assets/images/hfokayama/05_EN.png'),
          title: ['오카야마현 구라시키 미관지구 마치아루키 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '구라시키 역앞 관광 안내소(倉敷駅前観光案內所)','오카야마현 구라시키시 아치1-7-2'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://okayama.visit-town.com/visittour/cp_kurashiki-machi-2023/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['매진 시 대체품은 없습니다. 타매장을 이용해주세요. 더불어 매장에 따라 임시 휴업도 있습니다.'] },
                { cid: 2, text: ['해당 쿠폰과 다른 할인 내용을 중복하여 사용할 수 없습니다.'] },
                { cid: 3, text: ['고객이 이용하고 남은 미사용 쿠폰은 환불할 수 없으므로 미리 양해 부탁드립니다.'] },
                { cid: 4, text: ['해당 쿠폰의 도난, 분실 등에 관해서는 일절 책임을 지지 않습니다.'] },
                { cid: 5, text: ['유효기간은 2023년4월1일~2024년3월31일입니다.'] },
                { cid: 6, text: ['예고없이 이용가능한 매장 변동 및 제공내용이 변경될 수 있습니다.'] },
                { cid: 7, text: [' 1부(10장 묶음)당 1명이 이용할 수 있습니다.'] },
                { cid: 8, text: ['이용 매장에 따라 필요한 수량이 다릅니다. 입장/체험으로 10장(1부)가 필요한 시설도 있습니다. 다른 시설도 이용을 원하신다면 2부이상 구매해 주세요.'] },
                { cid: 9, text: ['손님이 직접 주의사항 등을 확인하여 시설에 방문해 주세요.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 6,
          img: require('@/assets/images/hfokayama/06_EN.png'),
          title: ['베티스 스토어&아울렛에서 사용할 수 있는 1,500엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒711-090 5-2-70 Kojimashimonocho, Kurashiki, Okayama  Betty Smith'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://betty.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1500엔 쿠폰을 사용하실 수 있습니다.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다.'] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hfokayama/07_EN.jpg'),
          title: ['청바지 마을 고지마 버스 1일 승차권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '[고지마 영업소]〒711-0913 3-2-10 Kojimaajino, Kurashiki, Okayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['http://shimoden.net/rosen/kikaku/jeans.html#oneday'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['실권으로 교환한 후에는 환불 불가합니다. 분실 및 도난 시에도 재발행되지 않습니다.'] },
                { cid: 2, text: ['천재지변 등으로 인해 버스가 지연되거나, 관광시설이 운영하지 않는 경우에도 환불 불가합니다. '] },
                { cid: 3, text: ['버스를 놓쳐서 택시 등을 이용하는 경우에도 환불 불가합니다.'] },
                { cid: 4, text: ['"진즈 버스(ジーンズバス)", "후레아이호", "도코하이호", 오지가타케 토잔구치(王子ヶ岳登山口)까지 운행하는 "오지가타케선"을 이용할 수 있습니다. '] },
                { cid: 5, text: ['이용하실 때는 반드시 고지마 영업소에서 1일 승차권 실권으로 교환해 주세요. ※버스 탑승 후 차 안에서의 교환은 불가합니다.'] },

                ]
            }
          ]
        },
        // 
        {
          id: 8,
          img: require('@/assets/images/hfokayama/08_EN.jpg'),
          title: ['WASHU BLUE RESORT 가사고(風籠) 당일 온천 티켓'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒711-0926 303-17, Shimotsuifukiage, Kurashiki, Okayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['http://www.kasago.jp.k.aah.hp.transer.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['당일 온천을 운영하지 않는 날이 있습니다. 자세한 내용은 홈페이지를 확인해 주세요.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 9,
          img: require('@/assets/images/hfokayama/09_EN.png'),
          title: ['JR오카야마역 앞 가전제품 판매점 "빅카메라 오카야마 에키마에점" 1,000엔 상품권 '],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒700-0023 1--1-1 Ekimaecho, Kita, Okayama 1F 종합안내 카운터'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.biccamera.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['현금으로 바꿀 수 없습니다.'] },
                { cid: 2, text: ['도난 및 분실 등에 대해서는 당사에서 일절 책임지지 않습니다.'] },
                { cid: 3, text: ['다른 상품권, 엽서, 우표, 담배 및 기타 당사가 지정한 상품 등으로 교환할 수 없습니다. '] },
                { cid: 4, text: ['각 점포의 영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
                { cid: 5, text: ['쿠폰 금액을 초과한 경우, 현지에서 추가 결제해 주세요.'] },
       
                ]
            }
          ]
        },
        // 
      ]
    }
  },
  created () {
    this.$router.push('/hfPage?id=HFOkayama&l=kr')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okayama-1-week-free-pass/kr', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>